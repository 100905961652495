import axios from 'axios';

const apiUrl = process.env.VUE_APP_GHOST_API_URL;
const apiKey = process.env.VUE_APP_GHOST_API_KEY;

const ghostApi = {
    posts: {
        getAll() {
            return axios.get(`${apiUrl}/${apiKey}`);
        },
        getSinglePost(postId) {
            return axios.get(`${apiUrl}/${apiKey}?id=${postId}`);
        }
    }
}

export default ghostApi;
