<template>
  <article class="post__detail container box" v-if="post && !isLoading">
    <section class="post__detail__header">
      <h1>{{ post.title }}</h1>
      <div>
        <span>{{ moment(post.published_at).format("MMMM DD, YYYY") }}</span> |
        <span>Team Emprops</span>
      </div>
    </section>
    <section class="post__detail__img">
      <img :src="post.feature_image" :alt="`Blog image ${post.id}`" />
    </section>
    <section class="post__detail__body">
      <p v-html="post.html"></p>
    </section>
  </article>
</template>

<script>
import ghostApi from "@/service/ghostApi.js";
import moment from "moment";

export default {
  data() {
    return {
      post: {},
      moment: moment,
      isLoading: false,
    };
  },
  async mounted() {
    await this.getPostById();
  },
  methods: {
    async getPostById() {
      this.isLoading = true;
      const postId = this.$route.params.postId;
      try {
        const { data } = await ghostApi.posts.getSinglePost(postId);
        this.post = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false; 
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post__detail {
  display: grid;
  grid-template-rows: (3, 1fr);
  row-gap: 26px;
  &.box {
    padding: 16px;
  }
  &.container {
    max-width: 720px;
    margin: 42px auto;
  }
  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    h1 {
      margin: 0px;
    }
  }
  &__img {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
::v-deep {
  .post__detail__body {
    color: var(--color-neutral-02);
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    p {
      color: var(--color-neutral-02);
    }
    a {
      color: var(--color-emerald);
      cursor: pointer;
    }
  }
}
@include respond-to("medium and down") {
  .post__detail {
    &.container {
      margin: 0px auto;
    }
  }
}
</style>
